define("solidcodersdev/components/footermenu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="w-full mt-6 lg:mt-0 md:w-1/3 lg:w-1/5">
    <LinkTo @route="index"><h6 class="font-semibold text-white-700 mb-4">Our company</h6></LinkTo>
    <ul>
      <li><a href="#team" class="block text-white-600 py-2">Team</a></li>
      <li><a href="#work" class="block text-white-600 py-2">Work</a></li>
      <li><a href="" class="block text-white-600 py-2">Press</a></li>
    </ul>
  </div>
  */
  {
    id: "fHW+x8BA",
    block: "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"w-full mt-6 lg:mt-0 md:w-1/3 lg:w-1/5\"],[12],[2,\"\\n  \"],[8,\"link-to\",[],[[\"@route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[10,\"h6\"],[14,0,\"font-semibold text-white-700 mb-4\"],[12],[2,\"Our company\"],[13]],\"parameters\":[]}]]],[2,\"\\n  \"],[10,\"ul\"],[12],[2,\"\\n    \"],[10,\"li\"],[12],[10,\"a\"],[14,6,\"#team\"],[14,0,\"block text-white-600 py-2\"],[12],[2,\"Team\"],[13],[13],[2,\"\\n    \"],[10,\"li\"],[12],[10,\"a\"],[14,6,\"#work\"],[14,0,\"block text-white-600 py-2\"],[12],[2,\"Work\"],[13],[13],[2,\"\\n    \"],[10,\"li\"],[12],[10,\"a\"],[14,6,\"\"],[14,0,\"block text-white-600 py-2\"],[12],[2,\"Press\"],[13],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    meta: {
      moduleName: "solidcodersdev/components/footermenu.hbs"
    }
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
  _exports.default = _default;
});