define("solidcodersdev/components/header", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="w-full md:h-1/3 bg-center bg-no-repeat bg-cover bg-opacity-40"
       style="background-image: url('/assets/images/headerbackground.png');">
   <Sharedmenu/>
  </div>
  
  */
  {
    id: "0j7ROrTi",
    block: "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"w-full md:h-1/3 bg-center bg-no-repeat bg-cover bg-opacity-40\"],[14,5,\"background-image: url('/assets/images/headerbackground.png');\"],[12],[2,\"\\n \"],[8,\"sharedmenu\",[],[[],[]],null],[2,\"\\n\"],[13],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    meta: {
      moduleName: "solidcodersdev/components/header.hbs"
    }
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
  _exports.default = _default;
});