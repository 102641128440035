define("solidcodersdev/router", ["exports", "@babel/runtime/helpers/esm/defineProperty", "solidcodersdev/config/environment"], function (_exports, _defineProperty2, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class Router extends Ember.Router {
    constructor() {
      super(...arguments);
      (0, _defineProperty2.default)(this, "location", _environment.default.locationType);
      (0, _defineProperty2.default)(this, "rootURL", _environment.default.rootURL);
    }
  }
  _exports.default = Router;
  Router.map(function () {
    this.route('terms');
    this.route('privacy');
    this.route('index', {
      path: '/'
    });
  });
});