define("solidcodersdev/components/workwith", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <section class="p-10 bg-gray-900">
    <div class="text-4xl sm:text-5xl text-center my-10 text-white">{{t "workwithus.who"}}</div>
  
    <div class="grid md:grid-cols-3 gap-3 m-5 max-w-8xl m-auto">
      <div class="text-white">
        <div class="px-14 py-6 mb-10 text-center">
          <div class="text-2xl font-bold text-green-400 mb-4">{{t "workwithus.indie"}}</div>
          <span>{{t "workwithus.indie_message"}}</span>
        </div>
      </div>
  
      <div class="text-white">
        <div class="px-14 py-6 mb-10 text-center">
          <div class="text-2xl font-bold text-green-400 mb-4">{{t "workwithus.startupsscaleups"}}</div>
          <span>{{t "workwithus.startupscalesups_message"}}</span>
        </div>
      </div>
  
      <div class="text-white">
        <div class="px-14 py-6 mb-10 text-center">
          <div class="text-2xl font-bold text-green-400 mb-4">{{t "workwithus.inhouseteams"}}</div>
          <span>{{t "workwithus.inhouseteams_message"}}</span>
        </div>
      </div>
    </div>
  </section>
  */
  {
    id: "CUunA0vf",
    block: "{\"symbols\":[],\"statements\":[[10,\"section\"],[14,0,\"p-10 bg-gray-900\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"text-4xl sm:text-5xl text-center my-10 text-white\"],[12],[1,[30,[36,0],[\"workwithus.who\"],null]],[13],[2,\"\\n\\n  \"],[10,\"div\"],[14,0,\"grid md:grid-cols-3 gap-3 m-5 max-w-8xl m-auto\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"text-white\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"px-14 py-6 mb-10 text-center\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"text-2xl font-bold text-green-400 mb-4\"],[12],[1,[30,[36,0],[\"workwithus.indie\"],null]],[13],[2,\"\\n        \"],[10,\"span\"],[12],[1,[30,[36,0],[\"workwithus.indie_message\"],null]],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"text-white\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"px-14 py-6 mb-10 text-center\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"text-2xl font-bold text-green-400 mb-4\"],[12],[1,[30,[36,0],[\"workwithus.startupsscaleups\"],null]],[13],[2,\"\\n        \"],[10,\"span\"],[12],[1,[30,[36,0],[\"workwithus.startupscalesups_message\"],null]],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n\\n    \"],[10,\"div\"],[14,0,\"text-white\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"px-14 py-6 mb-10 text-center\"],[12],[2,\"\\n        \"],[10,\"div\"],[14,0,\"text-2xl font-bold text-green-400 mb-4\"],[12],[1,[30,[36,0],[\"workwithus.inhouseteams\"],null]],[13],[2,\"\\n        \"],[10,\"span\"],[12],[1,[30,[36,0],[\"workwithus.inhouseteams_message\"],null]],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    meta: {
      moduleName: "solidcodersdev/components/workwith.hbs"
    }
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
  _exports.default = _default;
});