define("solidcodersdev/components/templateheader", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <header id="top" class="flex flex-col sm:flex-row items-start justify-between md:py-5 sm:py-0 relative header-background md:pl-12 sm:pl-2">
    <LinkTo @route="index"><a class="text-black hover:text-black font-semibold block pb-2 text-sm"> <h1 class="text-white text-3xl bg-green p-3"> {{t "general.title"}}</h1></a></LinkTo>
  </header>
  */
  {
    id: "Nj6tMxm1",
    block: "{\"symbols\":[],\"statements\":[[10,\"header\"],[14,1,\"top\"],[14,0,\"flex flex-col sm:flex-row items-start justify-between md:py-5 sm:py-0 relative header-background md:pl-12 sm:pl-2\"],[12],[2,\"\\n  \"],[8,\"link-to\",[],[[\"@route\"],[\"index\"]],[[\"default\"],[{\"statements\":[[10,\"a\"],[14,0,\"text-black hover:text-black font-semibold block pb-2 text-sm\"],[12],[2,\" \"],[10,\"h1\"],[14,0,\"text-white text-3xl bg-green p-3\"],[12],[2,\" \"],[1,[30,[36,0],[\"general.title\"],null]],[13],[13]],\"parameters\":[]}]]],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    meta: {
      moduleName: "solidcodersdev/components/templateheader.hbs"
    }
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
  _exports.default = _default;
});