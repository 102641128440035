define("solidcodersdev/components/menu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <nav class="w-full sm:w-auto self-end sm:self-center sm:flex flex-col sm:flex-row items-center h-full py-1 pb-4 sm:py-0 sm:pb-0 hidden">
    <a href="#faq" class="text-white hover:text-white py-3 px-6 font-bold">{{t "menu.faq_menu"}}</a>
    <a href="#services" class="text-white hover:text-white py-3 px-6 font-bold">{{t "menu.services_menu"}}</a>
    <a href="#contact" class="text-white hover:text-white py-3 px-6 font-bold">{{t "menu.contact_menu"}}</a>
    <button class="text-center text-white text-green-400 border-green-400 border-2 rounded mr-12 p-3" type="button">
      <a href="https://gabrielaradudeveloper.typeform.com/to/wQAtYoEO" target="_blank" rel="external nofollow noopener">
        {{t "menu.getintouch_menu"}}
      </a>
    </button>
  </nav>
  */
  {
    id: "X94be1lO",
    block: "{\"symbols\":[],\"statements\":[[10,\"nav\"],[14,0,\"w-full sm:w-auto self-end sm:self-center sm:flex flex-col sm:flex-row items-center h-full py-1 pb-4 sm:py-0 sm:pb-0 hidden\"],[12],[2,\"\\n  \"],[10,\"a\"],[14,6,\"#faq\"],[14,0,\"text-white hover:text-white py-3 px-6 font-bold\"],[12],[1,[30,[36,0],[\"menu.faq_menu\"],null]],[13],[2,\"\\n  \"],[10,\"a\"],[14,6,\"#services\"],[14,0,\"text-white hover:text-white py-3 px-6 font-bold\"],[12],[1,[30,[36,0],[\"menu.services_menu\"],null]],[13],[2,\"\\n  \"],[10,\"a\"],[14,6,\"#contact\"],[14,0,\"text-white hover:text-white py-3 px-6 font-bold\"],[12],[1,[30,[36,0],[\"menu.contact_menu\"],null]],[13],[2,\"\\n  \"],[10,\"button\"],[14,0,\"text-center text-white text-green-400 border-green-400 border-2 rounded mr-12 p-3\"],[14,4,\"button\"],[12],[2,\"\\n    \"],[10,\"a\"],[14,6,\"https://gabrielaradudeveloper.typeform.com/to/wQAtYoEO\"],[14,\"target\",\"_blank\"],[14,\"rel\",\"external nofollow noopener\"],[12],[2,\"\\n      \"],[1,[30,[36,0],[\"menu.getintouch_menu\"],null]],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    meta: {
      moduleName: "solidcodersdev/components/menu.hbs"
    }
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
  _exports.default = _default;
});