define("solidcodersdev/components/contact", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <section class="pb-5 relative block bg-gray-900" id="contact">
    <div class="bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20"
         style="height: 80px; transform: translateZ(0px);">
      <svg aria-hidden="true"
              class="absolute bottom-0 overflow-hidden"
              xmlns="http://www.w3.org/2000/svg"
              preserveAspectRatio="none"
              version="1.1"
              viewBox="0 0 2560 100"
              x="0"
              y="0">
        <polygon
                class="text-gray-900 fill-current"
                points="2560 0 2560 100 0 100">
        </polygon>
      </svg>
    </div>
    <div class="container mx-auto px-4 lg:pt-14 lg:pb-24">
      <div class="flex flex-wrap text-center justify-center">
        <div class="w-full lg:w-6/12 px-4">
          <h2 class="text-4xl font-semibold text-white">{{t "contact.useform_tocontact_us_title"}}</h2>
          <p class="text-lg leading-relaxed mt-4 mb-4 text-white">
            {{t "contact.useform_tocontact_us"}}
          </p>
        </div>
      </div>
    </div>
  </section>
  */
  {
    id: "BVo+6UDB",
    block: "{\"symbols\":[],\"statements\":[[10,\"section\"],[14,0,\"pb-5 relative block bg-gray-900\"],[14,1,\"contact\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"bottom-auto top-0 left-0 right-0 w-full absolute pointer-events-none overflow-hidden -mt-20\"],[14,5,\"height: 80px; transform: translateZ(0px);\"],[12],[2,\"\\n    \"],[10,\"svg\"],[14,\"aria-hidden\",\"true\"],[14,0,\"absolute bottom-0 overflow-hidden\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[14,\"preserveAspectRatio\",\"none\"],[14,\"version\",\"1.1\"],[14,\"viewBox\",\"0 0 2560 100\"],[14,\"x\",\"0\"],[14,\"y\",\"0\"],[12],[2,\"\\n      \"],[10,\"polygon\"],[14,0,\"text-gray-900 fill-current\"],[14,\"points\",\"2560 0 2560 100 0 100\"],[12],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"container mx-auto px-4 lg:pt-14 lg:pb-24\"],[12],[2,\"\\n    \"],[10,\"div\"],[14,0,\"flex flex-wrap text-center justify-center\"],[12],[2,\"\\n      \"],[10,\"div\"],[14,0,\"w-full lg:w-6/12 px-4\"],[12],[2,\"\\n        \"],[10,\"h2\"],[14,0,\"text-4xl font-semibold text-white\"],[12],[1,[30,[36,0],[\"contact.useform_tocontact_us_title\"],null]],[13],[2,\"\\n        \"],[10,\"p\"],[14,0,\"text-lg leading-relaxed mt-4 mb-4 text-white\"],[12],[2,\"\\n          \"],[1,[30,[36,0],[\"contact.useform_tocontact_us\"],null]],[2,\"\\n        \"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    meta: {
      moduleName: "solidcodersdev/components/contact.hbs"
    }
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
  _exports.default = _default;
});