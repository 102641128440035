define("solidcodersdev/components/newsletter", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <div class="max-w-screen-lg mx-auto">
    <div class="rounded flex md:shadow mt-12 mb-8">
      <img src="https://images.unsplash.com/photo-1508817172652-4be4be2795cb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80"
           class="w-0 md:w-1/4 object-cover rounded-l"/>
      <div class="px-4 py-2">
        <h3 class="text-3xl text-gray-800 font-bold">Subscribe to our newsletter</h3>
        <p class="text-xl text-gray-700">We sometimes write about the cool tech we are working with.</p>
        <form class="mt-4 mb-10">
          <input type="email" class="rounded bg-gray-100 px-4 py-2 border focus:border-green-400"
                 placeholder="example@email.com"/>
          <button class="px-4 py-2 rounded bg-purple-800 text-gray-100">
            Subscribe
            <i class='bx bx-right-arrow-alt'></i>
          </button>
          <p class="text-green-900 opacity-50 text-sm mt-1">No spam. Unsubscribe any time.</p>
        </form>
      </div>
    </div>
  </div>
  */
  {
    id: "T+yaZou3",
    block: "{\"symbols\":[],\"statements\":[[10,\"div\"],[14,0,\"max-w-screen-lg mx-auto\"],[12],[2,\"\\n  \"],[10,\"div\"],[14,0,\"rounded flex md:shadow mt-12 mb-8\"],[12],[2,\"\\n    \"],[10,\"img\"],[14,\"src\",\"https://images.unsplash.com/photo-1508817172652-4be4be2795cb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80\"],[14,0,\"w-0 md:w-1/4 object-cover rounded-l\"],[12],[13],[2,\"\\n    \"],[10,\"div\"],[14,0,\"px-4 py-2\"],[12],[2,\"\\n      \"],[10,\"h3\"],[14,0,\"text-3xl text-gray-800 font-bold\"],[12],[2,\"Subscribe to our newsletter\"],[13],[2,\"\\n      \"],[10,\"p\"],[14,0,\"text-xl text-gray-700\"],[12],[2,\"We sometimes write about the cool tech we are working with.\"],[13],[2,\"\\n      \"],[10,\"form\"],[14,0,\"mt-4 mb-10\"],[12],[2,\"\\n        \"],[10,\"input\"],[14,0,\"rounded bg-gray-100 px-4 py-2 border focus:border-green-400\"],[14,\"placeholder\",\"example@email.com\"],[14,4,\"email\"],[12],[13],[2,\"\\n        \"],[10,\"button\"],[14,0,\"px-4 py-2 rounded bg-purple-800 text-gray-100\"],[12],[2,\"\\n          Subscribe\\n          \"],[10,\"i\"],[14,0,\"bx bx-right-arrow-alt\"],[12],[13],[2,\"\\n        \"],[13],[2,\"\\n        \"],[10,\"p\"],[14,0,\"text-green-900 opacity-50 text-sm mt-1\"],[12],[2,\"No spam. Unsubscribe any time.\"],[13],[2,\"\\n      \"],[13],[2,\"\\n    \"],[13],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[]}",
    meta: {
      moduleName: "solidcodersdev/components/newsletter.hbs"
    }
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
  _exports.default = _default;
});