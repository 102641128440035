define("solidcodersdev/templates/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = Ember.HTMLBars.template({
    "id": "ya5cEsU9",
    "block": "{\"symbols\":[],\"statements\":[[8,\"header\",[],[[],[]],null],[2,\"\\n\"],[8,\"services\",[],[[],[]],null],[2,\"\\n\"],[8,\"workwith\",[],[[],[]],null],[2,\"\\n\"],[8,\"aboutus\",[],[[],[]],null],[2,\"\\n\"],[8,\"company\",[],[[],[]],null],[2,\"\\n\"],[8,\"faq\",[],[[],[]],null],[2,\"\\n\"],[8,\"contact\",[],[[],[]],null],[2,\"\\n\"],[8,\"footer\",[],[[],[]],null],[2,\"\\n\"]],\"hasEval\":false,\"upvars\":[]}",
    "meta": {
      "moduleName": "solidcodersdev/templates/index.hbs"
    }
  });
  _exports.default = _default;
});