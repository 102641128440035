define("solidcodersdev/components/sharedmenu", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = Ember.HTMLBars.template(
  /*
    <header id="top" class="flex flex-col sm:flex-row items-start justify-between md:py-5 sm:py-0 relative header-background md:pl-12 sm:pl-2">
    <h1 class="text-white text-3xl bg-green p-3"> {{t "general.title"}}</h1>
    <div id="menu"
         class="w-full sm:w-auto self-end sm:self-center sm:flex flex-col sm:flex-row items-center h-full py-1 pb-4 sm:py-0 sm:pb-0">
      <Menu/>
    </div>
    <div class="md:hidden sm:visible w-full sm:w-auto self-end sm:self-center sm:flex flex-col sm:flex-row items-center">
      <Mobilemenu/>
    </div>
  </header>
  */
  {
    id: "MRSvMHLd",
    block: "{\"symbols\":[],\"statements\":[[10,\"header\"],[14,1,\"top\"],[14,0,\"flex flex-col sm:flex-row items-start justify-between md:py-5 sm:py-0 relative header-background md:pl-12 sm:pl-2\"],[12],[2,\"\\n  \"],[10,\"h1\"],[14,0,\"text-white text-3xl bg-green p-3\"],[12],[2,\" \"],[1,[30,[36,0],[\"general.title\"],null]],[13],[2,\"\\n  \"],[10,\"div\"],[14,1,\"menu\"],[14,0,\"w-full sm:w-auto self-end sm:self-center sm:flex flex-col sm:flex-row items-center h-full py-1 pb-4 sm:py-0 sm:pb-0\"],[12],[2,\"\\n    \"],[8,\"menu\",[],[[],[]],null],[2,\"\\n  \"],[13],[2,\"\\n  \"],[10,\"div\"],[14,0,\"md:hidden sm:visible w-full sm:w-auto self-end sm:self-center sm:flex flex-col sm:flex-row items-center\"],[12],[2,\"\\n    \"],[8,\"mobilemenu\",[],[[],[]],null],[2,\"\\n  \"],[13],[2,\"\\n\"],[13]],\"hasEval\":false,\"upvars\":[\"t\"]}",
    meta: {
      moduleName: "solidcodersdev/components/sharedmenu.hbs"
    }
  });
  var _default = Ember._setComponentTemplate(__COLOCATED_TEMPLATE__, Ember._templateOnlyComponent());
  _exports.default = _default;
});